// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'bcd_name', 'mac_addr', 'description', 'do_refresh_wpa_key']}
  },
  create (config, {bcd_name_new, mac_addr_new, description_new, do_refresh_wpa_key_new = true}) {
    const params = { 'new': { 'bcd_name': bcd_name_new, 'mac_addr': mac_addr_new, 'description': description_new, 'do_refresh_wpa_key': do_refresh_wpa_key_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/macauth/client/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'mac_addr'], 'new': [ ]}
  },
  delete (config, {mac_addr_old}) {
    const params = { 'new': { }, 'old': { 'mac_addr': mac_addr_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/macauth/client/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, is_own, bcd_name, gpk_list, mac_addr, fetch_limit, fetch_offset, mac_addr_list, description_regexp, filter_params_dict, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'is_own': is_own, 'bcd_name': bcd_name, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'mac_addr': mac_addr, 'fetch_limit': fetch_limit, 'fetch_offset': fetch_offset, 'mac_addr_list': (mac_addr_list === null) ? null : JSON.stringify(mac_addr_list), 'description_regexp': description_regexp, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/macauth/client/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'mac_addr'], 'new': [ 'bcd_name', 'mac_addr', 'description', 'do_refresh_wpa_key']}
  },
  update (config, {bcd_name_new, mac_addr_old, mac_addr_new, description_new, do_refresh_wpa_key_new}) {
    const params = { 'new': { 'bcd_name': bcd_name_new, 'mac_addr': mac_addr_new, 'description': description_new, 'do_refresh_wpa_key': do_refresh_wpa_key_new}, 'old': { 'mac_addr': mac_addr_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/macauth/client/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
